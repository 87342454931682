<template>
  <div class="marketingCenter">
    <div class="title">
      <div>行销工具</div>
      <div
        style="font-size: 15px; color: #2e79de; cursor: pointer"
        @click="handlePush"
      >
        <i class="el-icon-search"></i>
        查看我的促销活动
      </div>
    </div>
    <div class="list">
      <div
        class="item"
        v-for="(item, i) in listData"
        :key="i"
        @click="toPush(item.path)"
      >
        <div class="yuan">
          <svg
            t="1657075673305"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="43491"
            width="34"
            height="34"
            style="position: absolute; right: -10px; top: -10px"
          >
            <path
              d="M245.76 286.72h552.96c124.928 0 225.28 100.352 225.28 225.28s-100.352 225.28-225.28 225.28H0V532.48c0-135.168 110.592-245.76 245.76-245.76z m133.12 348.16V401.408H348.16v178.176l-112.64-178.176H204.8V634.88h30.72v-178.176L348.16 634.88h30.72z m182.272-108.544v-24.576h-96.256v-75.776h110.592v-24.576h-141.312V634.88h143.36v-24.576h-112.64v-83.968h96.256z m100.352 28.672l-34.816-151.552h-34.816l55.296 233.472H675.84l47.104-161.792 4.096-20.48 4.096 20.48 47.104 161.792h28.672l57.344-233.472h-34.816l-32.768 151.552-4.096 30.72-6.144-30.72-40.96-151.552h-30.72l-40.96 151.552-6.144 30.72-6.144-30.72z"
              fill="#EE502F"
              p-id="43492"
            ></path>
          </svg>
          <svg
            v-if="i == 0"
            t="1657075070138"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="4028"
            width="30"
            height="30"
          >
            <path
              d="M823.7 144c36.6 0 71.7 14.5 97.6 40.4 25.9 25.9 40.4 61 40.4 97.6v120.5c0 15.7-8 30.3-21.2 38.7l-44.6 28.6c-15 9.6-24.4 26-25.1 43.8-0.7 17.8 7.2 34.9 21.4 45.7l51.5 39.4c11.4 8.7 18.1 22.2 18.1 36.6v110.9c0 35.5-14.1 69.5-39.2 94.6-25.1 25.1-59.1 39.2-94.6 39.2H195.7c-35.5 0-69.5-14.1-94.6-39.2-25-25-39.1-59-39.1-94.4v-111c0-14.3 6.7-27.9 18.1-36.6l51.3-39.2c14.3-10.9 22.3-28.1 21.6-46-0.7-17.9-10.1-34.4-25.2-44.2l-44.8-29c-13.1-8.5-21-23-21-38.6V282c0-76.2 61.8-138 138-138h623.7zM351 700c-18.8 0-34 15.2-34 34v82c0 18.8 15.2 34 34 34s34-15.2 34-34v-82c0-18.8-15.2-34-34-34z m0-284c-18.8 0-34 15.2-34 34v136.5c0 18.8 15.2 34 34 34s34-15.2 34-34V450c0-18.8-15.2-34-34-34z m0-242c-18.8 0-34 15.2-34 34v82c0 18.8 15.2 34 34 34s34-15.2 34-34v-82c0-18.8-15.2-34-34-34z"
              fill="#2E79DE"
              p-id="4029"
            ></path>
          </svg>
          <svg
            v-if="i == 1"
            t="1657075368104"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="28227"
            width="30"
            height="30"
          >
            <path
              d="M483.2 790.3L861.4 412c1.7-1.7 2.5-4 2.3-6.3l-25.5-301.4c-0.7-7.8-6.8-13.9-14.6-14.6L522.2 64.3c-2.3-0.2-4.7 0.6-6.3 2.3L137.7 444.8c-3.1 3.1-3.1 8.2 0 11.3l334.2 334.2c3.1 3.2 8.2 3.2 11.3 0z m122.7-533.4c18.7-18.7 49.1-18.7 67.9 0 18.7 18.7 18.7 49.1 0 67.9-18.7 18.7-49.1 18.7-67.9 0-18.7-18.7-18.7-49.1 0-67.9z m283.8 282.9l-39.6-39.5c-3.1-3.1-8.2-3.1-11.3 0l-362 361.3-237.6-237c-3.1-3.1-8.2-3.1-11.3 0l-39.6 39.5c-3.1 3.1-3.1 8.2 0 11.3l243.2 242.8 39.6 39.5c3.1 3.1 8.2 3.1 11.3 0l407.3-406.6c3.1-3.1 3.1-8.2 0-11.3z"
              p-id="28228"
              fill="#2E79DE"
            ></path>
          </svg>
          <svg
            v-if="i == 2"
            t="1657075492002"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="37016"
            width="30"
            height="30"
          >
            <path
              d="M946.2 553.66H766.9l0.35 73.56h-63.78l-0.35-73.56h-382l0.35 73.56h-63.78l-0.35-73.56H65v281.23a59.79 59.79 0 0 0 59.79 59.79h774.42A59.79 59.79 0 0 0 959 834.89V553.7q-6.4-0.03-12.8-0.04zM77.8 502.12h179.62l0.22-17.4a16 16 0 0 1 16-15.79h31.79a16 16 0 0 1 16 16.2l-0.21 17h381.83l0.22-17.4a16 16 0 0 1 16-15.79h31.78a16 16 0 0 1 16 16.2l-0.22 17h192V352.55a64 64 0 0 0-63.78-63.78H767.54V193.1a64 64 0 0 0-63.78-63.78H320.24a64 64 0 0 0-63.78 63.78v95.67H128.9a64 64 0 0 0-63.78 63.78v149.53q6.34 0.03 12.68 0.04z m243.27-308.91l0.11-0.11h381.64l0.11 0.11v95.56H321.07z"
              p-id="37017"
              fill="#2E79DE"
            ></path>
          </svg>
          <svg
            v-if="i == 3"
            t="1657075549349"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="40492"
            width="28"
            height="28"
          >
            <path
              d="M1014.073792 345.701486c-0.071631-0.551562-0.181125-1.069355-0.291642-1.509377-0.446162-2.145873-1.037632-4.213975-1.882883-6.202259l-86.534865-215.417211C910.413897 78.056774 869.025255 50.4009 816.975808 50.4009L209.568878 50.4009c-51.02 0-90.666976 27.136034-105.031125 70.032006L11.505917 340.239078c-0.438998 1.296529-0.924045 3.211135-1.329275 5.170766-6.754844 20.74651-10.150174 42.045605-10.150174 63.423495 0.072655 75.201749 41.34771 143.865595 107.615996 179.12361 27.281343 14.618952 59.435671 22.002107 95.537913 22.002107l0.112564 0c60.584843-0.145309 116.696816-26.762527 154.945955-72.323189 38.057781 45.148269 93.730755 71.618131 154.202011 71.991638 59.993372-0.446162 115.480104-27.022447 153.391553-72.173786 38.247092 45.556569 94.432743 72.101131 155.309228 72.101131 36.849256-0.220011 69.554122-7.937786 97.095385-23.074531 65.270563-35.624359 105.766882-104.033401 105.733113-178.422645C1024.006002 386.644991 1020.502201 365.233332 1014.073792 345.701486L1014.073792 345.701486zM799.035204 381.699352 208.312258 381.699352c-20.37505 0-36.914748-16.539698-36.914748-36.919864 0-20.374026 16.539698-36.913724 36.914748-36.913724l590.722946 0c20.412912 0 36.911678 16.539698 36.911678 36.913724C835.946882 365.159654 819.448116 381.699352 799.035204 381.699352L799.035204 381.699352zM799.035204 381.699352"
              p-id="40493"
              fill="#2E79DE"
            ></path>
            <path
              d="M947.438139 677.075663c0-20.415982-16.496719-36.913724-36.918841-36.913724-3.801583 0-7.233752 1.069355-10.667967 2.174526l0 0c-27.356045 10.82044-62.355163 16.650215-88.747253 15.21247-58.183144-3.102664-102.523001-14.6957-145.233756-51.058886-42.123376 35.923164-95.875604 56.370869-153.098887 56.890708-58.033741-0.37146-112.082728-14.917758-154.426115-50.90846-42.603307 36.3243-96.979752 54.004985-154.906046 54.004985l-0.221034 0c-35.364439 0-55.299467-6.714935-87.494727-18.605753-0.884137-0.438998-1.806135-0.665149-2.731204-0.992607-1.549286-0.590448-2.732228-0.9967-4.320399-1.588171 0 0-5.313006-0.921999-8.230452-0.921999l-0.073678 0c-20.380166 0-36.879955 16.571421-36.846186 36.990472l0.258896 197.838857c0 52.055586 42.418089 94.398973 94.581122 94.398973l694.715415-0.446162c52.088332-0.145309 94.472652-42.603307 94.472652-94.652753L947.438139 677.075663 947.438139 677.075663zM947.438139 677.075663"
              p-id="40494"
              fill="#2E79DE"
            ></path>
          </svg>
        </div>
        <div>
          <div style="font-size: 16px; margin-bottom: 6px">{{ item.name }}</div>
          <div style="font-size: 14px; color: #a1a1a1">{{ item.desc }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      listData: [
        { name: "优惠券", desc: "增加转化率", path: "coupons" },
        { name: "我的折扣活动", desc: "增加转化率", path: "discount" },
        { name: "套装优惠", desc: "通过套装折扣售卖更多种商品", path: "suit" },
        { name: "商店的限时选购", desc: "增加转化率", path: "limit" },
      ],
    };
  },
  methods: {
    handlePush() {
      this.$router.push("salesPromotion");
    },
    toPush(path) {
      this.$router.push(path);
    },
  },
};
</script>
<style lang="less" scoped>
.marketingCenter {
  background: #fff;
  padding: 30px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  .title {
    font-size: 18px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 32%;
      background: #fafafa;
      padding: 20px 20px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      margin-bottom: 18px;
      cursor: pointer;
      .yuan {
        width: 62px;
        height: 62px;
        border-radius: 50%;
        background: #e5eefb;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }
    }
  }
}
</style>